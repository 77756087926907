/*
  import { hoaUtility } from '@/services/Hoa/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import _get from 'lodash/get'
import { notifyProblem, notifyError } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = false

/* eslint-disable no-empty-pattern */
const actions = {
  //Get by Id
  async getHoaById({}, { hoaID }) {
    try {
      console.debug('in getHoaById...')
      const results = await kms.get(`/Hoa/List`)

      const filtered = results.hoas.filter(f => f.hoaID === hoaID)

      if (isDebug == true) console.debug('getHoaById=' + JSON.stringify(filtered[0]))

      return {
        result: filtered[0]
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the hoa information.`)
      console.error(exception)
    }

    return {
      result: {}
    }
  },

  async retrieveGoogleMapSignature({ dispatch }, { payload, done }) {
    if (isDebug == true)
      console.debug('...in retrieveGoogleMapSignature=' + JSON.stringify(payload))
    try {
      const results = await kms.post(
        `/Utilities/GoogleMapUtility/GoogleMapSignUrl?url=${encodeURIComponent(
          payload.url
        )}&signatureOnly=true`
      )

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results) {
        const result = {
          signature: results.toString()
        }

        console.debug(`Successfully retrived google map signature. ` + JSON.stringify(result))

        done({ result })
      } else {
        notifyError('There was a problem retrieving the google map signature.')
      }
    } catch (exception) {
      notifyError(`There was a problem retrieving the google map signature.`)
      console.error(exception)
    }
  },

  async retrieveGoogleAddress({ dispatch }, { lat, lng, done }) {
    if (isDebug == true) console.debug('...in retrieveGoogleAddress...')
    try {
      const address = await kms.post(
        `/Utilities/GoogleMapUtility/GoogleMapRetrievePhysicalAddress?lat=${lat}&lng=${lng}`
      )

      //console.debug('results=' + address)

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (address) {
        const result = {
          address
        }
        done({ result })
      } else {
        notifyError('There was a problem retrieving the google map address.')
      }
    } catch (exception) {
      notifyError(`There was a problem retrieving the google map address.`)
      console.error(exception)
    }
  },

  async googleAddressExistsInSystem({ dispatch }, { hoaID, address, done }) {
    if (isDebug == true) console.debug('in googleAddressExistInSystem...')
    try {
      const validatedAddress = await kms.get(
        `/Roster/Unit/CheckForMatchingUnitAddress?hoaId=${hoaID}&address=${address}`
      )

      //console.debug('results=' + validatedAddress)

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (validatedAddress) {
        const result = {
          validatedAddress
        }
        done({ result })
      } else {
        notifyError('There was a problem validating the google map address.')
      }
    } catch (exception) {
      notifyError(`There was a problem validating the google map address.`)
      console.error(exception)
    }
  },

  // Address List
  async loadDropDownAddressList(
    {},
    { hoaID, startRecord = 1, recordCount = 10000, includeOwnerContacts = false }
  ) {
    try {
      const params = {
        hoaID: hoaID,
        includeOwnerContacts,
        startRecord,
        recordCount
      }

      if (isDebug == true) console.debug('params=' + JSON.stringify(params))

      const { results: addresses } = await kms.get('/Roster/Unit/List', { params })

      let dropDownAddresses = addresses
        .map(a => ({
          value: a.address ? a.address.addressID : '',
          label: a.address
            ? a.address.addressOne +
              ', ' +
              a.address.city +
              ' ' +
              a.address.state +
              '  ' +
              a.address.postalCode
            : '',
          unitID: a.unitID ? a.unitID : 0,
          primaryOwnerID: a.primaryOwnerID ? a.primaryOwnerID : 0
        }))
        .filter(f => f.label.length > 0 && f.value > 0)
        .sort((a, b) => a.label - b.label)

      dropDownAddresses.unshift({ value: '', label: '<-Select Address->' })

      if (isDebug == true)
        console.debug('address drop down list=' + JSON.stringify(dropDownAddresses))

      return {
        list: dropDownAddresses
      }
    } catch (exception) {
      notifyProblem(`The address list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  // Vendors List
  async loadVendors({}, { hoaID }) {
    try {
      const params = {
        hoaID: hoaID
      }
      const { results: vendors } = await kms.get('/Vendors/Vendor/List', { params })

      const vendorDropDownList = vendors
        .map(v => ({
          value: v.vendorID,
          label: v.name ? v.name : ''
        }))
        .filter(f => f.label.length > 0 && f.value > 0)
        .sort((a, b) => a.label - b.label)

      if (isDebug == true) console.debug('loadVendors=' + JSON.stringify(vendorDropDownList))

      return {
        list: vendorDropDownList
      }
    } catch (exception) {
      notifyProblem(`The vendor list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  // Hoa List
  async loadHoasByGlobalVendorId({}, { vendorGlobalID }) {
    try {
      const params = {
        vendorGlobalID
      }
      const { results: hoas } = await kms.get('/Vendors/Vendor/HoaListForGlobalVendor', { params })

      const hoaDropDownList = hoas
        .map(v => ({
          value: v.hoaID,
          label: v.name ? v.name : ''
        }))
        .filter(f => f.label.length > 0 && f.value > 0)
        .sort((a, b) => a.label - b.label)

      hoaDropDownList.unshift({ value: 0, label: '<-All Hoas->' })

      if (isDebug == true)
        console.debug('loadHoasByGlobalVendorId=' + JSON.stringify(hoaDropDownList))

      return {
        list: hoaDropDownList
      }
    } catch (exception) {
      notifyProblem(`The hoa list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  // Owners List
  async loadDropDownOwnersList(
    {},
    { hoaID, startRecord = 1, recordCount = 10000, includeOwnerContacts = false } = {}
  ) {
    try {
      const { results: owners } = await kms.get('/Roster/Unit/List', {
        params: {
          hoaID: hoaID,
          includeOwnerContacts,
          startRecord,
          recordCount
        }
      })

      const dropDownOwners = owners
        .map(a => ({
          value: _get(a, ['primaryOwner', 'ownerID'], ''),

          label: a.primaryOwner
            ? `${a.primaryOwner.name} - ${
                a.address && a.address != undefined && a.address.addressOne
                  ? a.address.addressOne
                  : ''
              }`
            : '',
          ownername: a.primaryOwner ? a.primaryOwner.name : ''
        }))
        .filter(f => f.label.length > 0 && f.value > 0)

      if (isDebug == true) console.debug('owner drop down list=' + JSON.stringify(dropDownOwners))

      return {
        list: dropDownOwners
      }
    } catch (e) {
      notifyProblem(e)
    }

    return {
      list: null
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const hoaUtility = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
