/*
  import { base64toBlob, showPdf } from '@/services/blobutility'
*/

export const base64toBlob = (b64Data, contentType) => {
  const sliceSize = 512
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

export const showPdf = blob => {
  var newBlob = new Blob([blob], { type: 'application/pdf' })

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob)
  } else {
    const data = window.URL.createObjectURL(newBlob)

    var link = document.createElement('a')
    link.download = 'download.pdf'
    link.setAttribute('download', 'download.pdf')
    link.href = data

    window.open(link, '_blank')
  }

  return
}

export const downloadPdf = blob => {
  var newBlob = new Blob([blob], { type: 'application/pdf' })

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob)
  } else {
    const data = window.URL.createObjectURL(newBlob)
    var link = document.createElement('a')
    link.download = 'download.pdf'
    link.setAttribute('download', 'download.pdf')
    link.href = data
    link.click()

    setTimeout(function() {
      window.URL.revokeObjectURL(data)
      100
    })
  }

  return
}

export const showAnyType = (blob, mimeType) => {
  console.debug('showAnyType...')
  var newBlob = new Blob([blob], { type: mimeType })
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob)
  } else {
    const data = window.URL.createObjectURL(newBlob)
    var link = document.createElement('a')
    link.href = data

    window.open(data, '_blank')

    setTimeout(function() {
      window.URL.revokeObjectURL(data)
      100
    })
  }

  return
}
