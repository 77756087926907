<template>
  <b-button
    v-if="(visible && visible === true) || visible === undefined"
    @click.prevent="onClick"
    :class="
      isprimary !== undefined && isprimary === true
        ? 'button is-primary is-small is-outline is-rounded'
        : 'button is-info is-light is-small is-outline is-rounded'
    "
    :title="tooltip"
    :disabled="disabled && disabled !== undefined && disabled === true"
    >{{ text }}</b-button
  >
</template>

<script>
export default {
  props: ['onClick', 'text', 'visible', 'disabled', 'isprimary', 'tooltip']
}
</script>
